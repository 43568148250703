import React from 'react';
import axios from 'axios';
import CryptoJS from 'crypto-js';

const userPrivileges = async () => {
  const getToken = async () => {
    try {
      const apiResponse = await axios.post(
        "https://marineeyeapi.online/auth/v1/token",
        {
          'username':process.env.REACT_APP_API_KEY,
          'password':process.env.REACT_APP_SECRET
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (!apiResponse.data) {
        alert("Wrong username or password");
      } else {
        const tokens = apiResponse.data; // Assuming the API response contains the tokens
        return tokens; // Return the tokens
      }
    } catch (e) {
      return null; // Return null in case of an error
    }
  };
  const SECRET_KEY = process.env.REACT_APP_SECRET_KEY; 
 const bytes = CryptoJS.AES.decrypt(localStorage.getItem("userProfile"), SECRET_KEY);
    
    let userProfile = bytes.toString(CryptoJS.enc.Utf8);
   let lsObject = JSON.parse(userProfile);
   //console.log("****************");
  //console.log(lsObject.id);
  if (lsObject[0] ){
    lsObject = lsObject[0];
  }
  const apiUrl = 'https://marineeyeapi.online/api/auth/v1/users/id/'+lsObject.id;


  // console.log("****************");
  // console.log(lsObject);

  try {
    const token = await getToken();
    if (token) {
      const apiResponse = await axios.get(
        apiUrl,
        {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      
   console.log(apiResponse);
     
     const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(apiResponse.data), SECRET_KEY).toString();
    localStorage.setItem("userProfile", encryptedValue);
     
    } else {
      console.error("Failed to obtain token");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

export default userPrivileges;
