import axios from "axios";
import jwtInterceptor from "helpers/jwInterceptor";
import { createContext, useState } from "react";
import { toast } from 'react-toastify';
import Cookies from 'js-cookie';
import { useDispatch } from "react-redux";
import {loginButtonClick1} from 'auth/authSlice';
import CryptoJS from 'crypto-js';

const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  
  const dispatch = useDispatch();
  const getToken = async () => {
  try {
    const apiResponse = await axios.post(
      "https://marineeyeapi.online/auth/v1/token",
      {
        'username':process.env.REACT_APP_API_KEY,
        'password':process.env.REACT_APP_SECRET
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!apiResponse.data) {
      alert("Wrong username or password");
    } else {
      const tokens = apiResponse.data; // Assuming the API response contains the tokens
      
      return tokens; // Return the tokens
    }
  } catch (e) {
    return null; // Return null in case of an error
  }
};
  const [user, setUser] = useState(() => {
    const SECRET_KEY = process.env.REACT_APP_SECRET_KEY;
    if (localStorage.getItem("userProfile")) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem("userProfile"), SECRET_KEY);
    //console.log(bytes);
    let userProfile = bytes.toString(CryptoJS.enc.Utf8);


    if (userProfile) {
      return JSON.parse(userProfile);
    }
  } 
  });


//   const login = async (payload) => {
//     try{
//        getToken().then((token)=>{
//       axios.post(
//       "https://marineeyeapi.online/api/auth/v1/signin",
//       payload  ,{
//               headers: {
//         'Authorization': `Bearer ${token}`,
//         'Content-Type': 'application/json',
//       }
//             },{withCredentials: true}
// 
//     ).then(apiResponse=>{
//     console.log(apiResponse);
//     if(!apiResponse.data){
//       alert("wrong username or password");
//     }else{
//       setUser(apiResponse.data);
//     localStorage.setItem("userProfile", JSON.stringify(apiResponse.data));
//     //console.log(localStorage.getItem("userProfile"));
// 
//     window.location.href = "/";
//     }
//     }, (reason) => {
// dispatch(loginButtonClick1("Invalid Username of Password"));
//      
//     });
//     })
// 
//     }catch(e){
//      dispatch(loginButtonClick1("Invalid Username of Password"));
//     }
//     
// 
//     
//   };

  const registerUser = async (payload) => {
getToken().then((token)=>{
    axios.post(

      "https://marineeyeapi.online/api/auth/v1/register",
      payload,{
              headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
            }
    ).then(apiResponse=>{
      toast(apiResponse.data, { className: 'success' });
      window.location.href = "/users";
    });

  })

    
      //alert(apiResponse.data);
      
   
      
   
  };

  

  const updateUser = async (payload,id) => {
    getToken().then((token)=>{
    axios.put("https://marineeyeapi.online/api/auth/v1/users/"+id,{
      
        "firstname": payload.firstname,
        "lastname": payload.lastname,
        "username":payload.username,
        "email": payload.email,
        "roles": payload.roles,
        "password": payload.password
        
      
    },{
              headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
            }).then(apiResponse=>{
              window.location.href = "/users";
            });
  });
  

  };

  const deleteUser = async (id) => {
    getToken().then((token)=>{
    axios.delete("https://marineeyeapi.online/api/auth/v1/users/"+id,{
              headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
            }).then(apiResponse=>{
              window.location.href = "/users";
            });
    
  })
  

  };

  

  const logout = async () => {
    
getToken().then((token)=>{
    axios.post(
      "https://marineeyeapi.online/api/auth/v1/signout",{
      
        "payload": "logout",
       
        
      
    },{
              headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
            },{withCredentials: true}
    ).then(apiResponse=>{
      localStorage.removeItem("userProfile");
    setUser(null);
    window.location.href = "/login";
    });
    
  });
}

  return (
    <AuthContext.Provider value={{ logout, registerUser,deleteUser, updateUser, user}}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;