import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  isLogin: IS_DEMO,
  currentUser: IS_DEMO ? DEFAULT_USER : {},
  errormessage: ""
};


const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      console.log(action.payload);
      state.isLogin = true;
    },
    loginButtonClick1(state, action) {
      // console.log(action.payload);
      state.errormessage = action.payload
    },
  },
});

export const { setCurrentUser } = authSlice.actions;
export const { loginButtonClick1} = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
