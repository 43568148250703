import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  showingNavMenu: '',
  vessels: {vesselList:[]},
  active:false
};

const layoutSlice = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    layoutShowingNavMenu(state, action) {
      state.showingNavMenu = action.payload;
    },
    searchButtonClick1(state, action) {
      // console.log(action.payload);
      state.vessels = action.payload;
    },
    showLoad(state,action){
      state.active=action.payload;
    }
  }
});
export const { layoutShowingNavMenu} = layoutSlice.actions;
export const { searchButtonClick1} = layoutSlice.actions;
export const { showLoad} = layoutSlice.actions;
const layoutReducer = layoutSlice.reducer;

export default layoutReducer;
