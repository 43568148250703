import React, { memo } from 'react';
import axios from 'axios';
import { Route, Redirect } from 'react-router-dom';
import ProtectedRoute from '../../components/Auth/ProtectedRoute';

const RouteItem = ({ redirect = false, exact = false, path, to, component }) => {
  if (!path && !to) {
    return () => {};
  }
  if (redirect) {
    const props = {};
    if (path) props.from = path;
    if (exact) props.exact = exact;
    if (to) props.to = to;
    return <Redirect {...props} />;
  }
  const props = {};
  if (path) props.path = path;
  if (exact) props.exact = exact;
  if (component) props.component = component;
  //console.log(path);

  if(path==="/Map" || path==="/Map/Map"){
    
      return (<><ProtectedRoute  privileges={"map"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }else if(path==="/Map/text_view"){
    
    
     return (<><ProtectedRoute privileges={"text"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);
  }else if(path==="/Map/voyage_replay"){
      return (<><ProtectedRoute privileges={"voyage"} requiredRoles={['ROLE_FMC','ROLE_ADMIN','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);
  }else if(path==="/vessels"){
      return (<><ProtectedRoute privileges={"vessels"} requiredRoles={['ROLE_FMC','ROLE_ADMIN','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);
  }else if(path==="/users"){
      return (<><ProtectedRoute privileges={"users"}  requiredRoles={['ROLE_ADMIN','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);
  }else if(path==="/map"){
     return (<><ProtectedRoute  privileges={"map"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }else if(path==="/alerts"){
    return (<><ProtectedRoute  privileges={"alert"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }
  else if(path==="/fleets/Fleets"){
    return (<><ProtectedRoute  privileges={"fleets"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }else if(path==="/fleets/harbour_arrival"){
    return (<><ProtectedRoute  privileges={"harbour_arrivals"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }
  else if(path==="/fish_forecasting"){
    return (<><ProtectedRoute  privileges={"fish_forecasting"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }
  else if(path==="/fleets/border_crossing"){
    return (<><ProtectedRoute  privileges={"borderCrossing"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }
  // else if(path==="/vessels/group"){
  //   return (<><ProtectedRoute  privileges={"groupVessels"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  // }
  else if(path==="/Map/zone_manager"){
    return (<><ProtectedRoute  privileges={"zoneManager"} requiredRoles={['ROLE_FMC','ROLE_ADMIN' ,'ROLE_OFFICER','ROLE_SUPERADMIN']}><Route {...props} /></ProtectedRoute></>);   
  }
  else{
    return (<><Route {...props} /></>);
  }

  // if (path==="/Map/index" || path==="/Map/text_view" || path==="/Map/voyage_replay" || path==="/vessels/add_vessel" || path==="/vessels/group" || path==="/users") {
  //   return (<><ProtectedRoute requiredRoles={['ROLE_FMC']}><Route {...props} /></ProtectedRoute></>);
  // }else{
  //   return (<><Route {...props} /></>);
  // }
  // return <Route {...props} />
  
  
};

export default memo(RouteItem);
