import axios from "axios";

const jwtInterceptor = axios.create({});

jwtInterceptor.interceptors.response.use(
    (response) => {
    return response;
}, async (error) => {
    if (error.response.status == "401") {
       let payload = {"username": "mod", "password:": "12345678"}
         
        let apiresponse = await axios.post(
            "http://localhost:8500/api/auth/refreshtoken",
            payload, {withCredentials: true}
          ).
       
       catch((refreshTokenAPIError) => {
        localStorage.removeItem("userProfile");
        return Promise.reject(refreshTokenAPIError);
    });
     //console.log(apiresponse.data);
     return axios(error?.config);
    
    }

    Promise.reject(error);
}
);

export default jwtInterceptor;