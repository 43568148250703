// import React from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Form ,Alert } from 'react-bootstrap';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import LayoutFullpage from 'layout/LayoutFullpage';
import CsLineIcons from 'cs-line-icons/CsLineIcons';
import HtmlHead from 'components/html-head/HtmlHead';

import { useContext} from "react";
import { useDispatch } from "react-redux";
import  { Redirect } from 'react-router-dom';
import { useJwt, isExpired, decodeToken } from 'react-jwt';
import HTTP_STATUS_CODES from 'http-status-codes';
import { serialize } from 'cookie';
import axios from 'axios'
import AuthContext from 'components/Auth/AuthContext';
import { setCurrentUser } from 'auth/authSlice';
import Cookies from 'js-cookie';
import React, { useRef, useEffect, useState } from 'react';
import { useSelector , connect } from 'react-redux';
import CryptoJS from 'crypto-js';
// import jwt from 'react-jwt';


const Login = () => {

const SECRET_KEY = 'Ahe8Bl10vlPhyq2OGjYgsU9oiHOCB9voQT4WpE7cDAKIey7SPYOJ9DnA25iSBE';
  const userNameRef=useRef();
  const passwordRef=useRef();

  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loginError, setLoginError] = useState('');
  const [user, setUser] = useState(() => {
    if (localStorage.getItem("userProfile")) {
    const bytes = CryptoJS.AES.decrypt(localStorage.getItem("userProfile"), SECRET_KEY);
    console.log(bytes);
    let userProfile = bytes.toString(CryptoJS.enc.Utf8);


    if (userProfile) {
      return JSON.parse(userProfile);
    }
  } 
  });

  const {errormessage}  =  useSelector((state)=>state.auth);
//alert(process.env.REACT_APP_API_KEY)
const getToken = async () => {
  try {
    const apiResponse = await axios.post(
      "https://marineeyeapi.online/auth/v1/token",
      {
        'username': process.env.REACT_APP_API_KEY,
        'password': process.env.REACT_APP_SECRET
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );

    if (!apiResponse.data) {
      alert("Wrong username or password");
    } else {
      const tokens = apiResponse.data; // Assuming the API response contains the tokens
      
      return tokens; // Return the tokens
    }
  } catch (e) {
    return null; // Return null in case of an error
  }
};
  const login = async (payload) => {
    try{
       getToken().then((token)=>{
      axios.post(
      "https://marineeyeapi.online/api/auth/v1/signin",
      payload  ,{
              headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      }
            },{withCredentials: true}

    ).then(apiResponse=>{
    console.log(apiResponse);
    if(!apiResponse.data){
      alert("wrong username or password");
    }else{
      setUser(apiResponse.data);
       const encryptedValue = CryptoJS.AES.encrypt(JSON.stringify(apiResponse.data), SECRET_KEY).toString();
    localStorage.setItem("userProfile", encryptedValue);

    console.log(localStorage.getItem("userProfile"));
    //console.log(localStorage.getItem("userProfile"));

    window.location.href = "/";
    }
    }, (reason) => {
      setLoginError("Invalid Username or Password");
// dispatch(loginButtonClick1("Invalid Username of Password"));
     
    });
    })

    }catch(e){
      setLoginError("Invalid Username or Password");
     // dispatch(loginButtonClick1("Invalid Username of Password"));
    }
    

    
  };
useEffect(() => {
   

    



    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



// Generate JSX code for error message
const renderErrorMessage = (name) =>
  name === errorMessages.name && (
    <div className="error">{errorMessages.message}</div>
  );


  const title = 'Login';
  const description = 'Login Page';

  // const {login, user} = useContext(AuthContext);
  const dispatch = useDispatch();
  
 



const loginHandler = async () => {
  const username = userNameRef.current.value;
 const password = passwordRef.current.value;
 if(username==""){
  setLoginError("Please insert username");
 }else if(password==""){
  setLoginError("Please insert password");
 }else if(username=="" && password==""){
  setLoginError("Please insert username & password");
 }else{
  let payload = {
    username: username,
    password: password
  };

  //await login(payload);
  login(payload);
  console.log(errormessage);
   


    dispatch(setCurrentUser(user));
    console.log(user) ;
    //alert(value);
   
  
 } 
  
}
  
  const leftSide = (
    <div className="min-h-100 d-flex align-items-center">
      <div className="w-100 w-lg-75 w-xxl-50">
        
      </div>
    </div>
  );

  const rightSide = (
    <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
      <div className="sw-lg-50 px-5">
        <div className="sh-11">
          <img  src='../../logo.png' width={'300px'} alt="fireSpot"/>
        </div>
        <div className="mb-5">
          <h2 className="cta-1 mb-0 text-primary">Welcome,</h2>
          <h2 className="cta-1 text-primary">let's get started!</h2>
        </div>
        <div className="mb-5">
          <p className="h6">Please use your credentials to login.</p>
          <p className="h6">
            If you are not a member, please contact Admin
          </p>
        </div>
        <div>
        {!loginError ? (
        <p></p>
      ) : (
        <Alert variant="danger">{loginError}</Alert>
      )}
          <form id="loginForm" className="tooltip-end-bottom" >
         
         
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="email" />
              <input ref={userNameRef} className="form-control" id="username" type="text"  name="uname"  placeholder="Email"  />
              {renderErrorMessage("uname")}
              {/* {errors.email && touched.email && <div className="d-block invalid-tooltip">{errors.email}</div>} */}
            </div>
            <div className="mb-3 filled form-group tooltip-end-top">
              <CsLineIcons icon="lock-off" />
              <input ref={passwordRef} className="form-control" id="password" type="password" name="pass"   placeholder="Password" />
              {renderErrorMessage("pass")}
              <NavLink className="text-small position-absolute t-3 e-3" to="/forgot-password">
                Forgot?
              </NavLink>
              {/* {errors.password && touched.password && <div className="d-block invalid-tooltip">{errors.password}</div>} */}
            </div>
            <Button size="lg" onClick={loginHandler}>
              Login
            </Button>
          </form>
        </div>
      </div>
    </div>
  );

  const renderForm = (
    <div className="form">
     
    </div>
  );

  
    
      
    

  return (
    <>
   <HtmlHead title={title} description={description} />
      <LayoutFullpage left={leftSide} right={rightSide} />
      
      {isSubmitted ? <Redirect to='/Map'  /> : renderForm}
    </>
  );
};

export default Login;
